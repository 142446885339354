import { Autocomplete, Box, Button, Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { PDFDownloadLink } from '@react-pdf/renderer';
import React from 'react';
import Pdf from './Pdf';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { LoadingButton } from '@mui/lab';
const ReportDetails = (props) => {
    const { setSubReportId, pdfCnt, index, open, handleChange, individualReports, reportsType, report, subReportType, subReport, subReportId, handleReportDelete } = props
    return (
        <TableRow >
            <TableCell
                style={{ paddingBottom: 0, paddingTop: 0 }}
                colSpan={6}
                padding="checkbox"
            >
                <Collapse
                    in={open.includes(index)}
                    timeout="auto"
                    unmountOnExit
                    id="panel1a-header"
                >
                    <Box sx={{ margin: 1 }}>
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography gutterBottom component="div" variant="h6">
                                Details
                            </Typography>
                            <Autocomplete
                                disablePortal
                                onChange={(event, newValue) => handleChange(event, individualReports)}
                                id="sub-reports"
                                sx={{ width: 320, marginVertical: 20 }}
                                getOptionLabel={option => option}
                                options={reportsType}
                                renderInput={(params) => <TextField {...params} label="select report" />}
                            />
                        </Box>
                        <Table size="small" aria-label="purchases" >
                            <TableHead>
                                <TableRow>

                                    <TableCell

                                        align="center">
                                        Serial No
                                    </TableCell>
                                    <TableCell

                                        align="center">
                                        {subReportType}
                                    </TableCell>
                                    <TableCell

                                        align="center">
                                        Happiness Factor
                                    </TableCell>
                                    <TableCell

                                        align="center">
                                        Download
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {subReport.map((item, index) =>
                                <TableBody key={item.id}>
                                    <TableRow>
                                        <TableCell
                                            align="center">
                                            {index + 1}
                                        </TableCell>
                                        <TableCell
                                            align="center">

                                            {
                                                subReportType === "Industry" ? item?.industry :
                                                    subReportType === "Location" ? item?.location :
                                                        subReportType === "Department" ? item?.department :
                                                            item?.role}
                                        </TableCell>
                                        <TableCell
                                            align="center">
                                            {item?.happiness_score}
                                        </TableCell>
                                        <TableCell align="center">
                                            {subReportId === index + 1 ?
                                                <PDFDownloadLink document={
                                                    <Pdf
                                                        pdfCnt={pdfCnt}
                                                        item={item}
                                                        img={report.img_url}
                                                        email={report.email}
                                                        name={report.company_name}
                                                        subReportTypes={
                                                            subReportType === "Industry" ? item?.industry :
                                                                subReportType === "Location" ? item?.location :
                                                                    subReportType === "Department" ? item?.department :
                                                                        item?.role}

                                                    />} fileName="FORM">
                                                    {({ loading, error }) => (!loading ? <IconButton color="success">
                                                        <FileDownloadOutlinedIcon />
                                                    </IconButton> : <LoadingButton loading variant="outlined">
                                                        Submit
                                                    </LoadingButton>)}
                                                </PDFDownloadLink> :
                                                <IconButton onClick={() => setSubReportId(index + 1)}>
                                                    <FileDownloadOutlinedIcon />
                                                </IconButton>}

                                        </TableCell>
                                        <TableCell align="center">
                                            <Button onClick={() => handleReportDelete(item.id)} variant="outlined" color="error" startIcon={<DeleteIcon />}>
                                                DELETE
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>

                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    );
};

export default ReportDetails;