import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({

    description: {
        width: "60%",
    },
    xyz: {
        width: "40%",
    },
});

const TableHeader = ({ headers, type }) => {
    const head = headers.map((item, index) => (
        <>
            {type ?
                <Text key={index + 2} style={{
                    width: index === 1 ? "60%" : "10%",
                    textAlign: 'center',
                    backgroundColor: '#FF914D',
                    fontSize: "10px",
                    paddingVertical: "5px"
                }}>{item}</Text> :
                <Text key={index + 1} style={{
                    width: index === 0 ? "4%" :
                        index === 1 ? "50%" :
                            index === 2 ? "10%" :
                                index === 3 ? "20%"
                                    : "16%",
                    textAlign: 'center',
                    backgroundColor: '#FF914D',
                    fontSize: "10px",
                    paddingVertical: "5px"
                }}>{item}</Text>}
        </>
    ));
    return <Fragment>{head}</Fragment>;
};

export default TableHeader;