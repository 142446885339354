import { useState, useEffect } from 'react';
import { Button, Stack, Typography, Card, Box, TextField, Drawer } from '@mui/material';
// components

import Page from '../components/Page';
import UserCard from '../components/user/UserCard';
import { deleteRequest, GET_API, postRequest } from 'src/utils/api';
import CustomSkeleton from 'src/components/CustomSkeleton';

import SingleSelector from 'src/components/SingleSelector';
import AddUserDrawer from 'src/components/user/AddUserDrawer';
import UpdateUserDrawer from 'src/components/user/UpdateUserDrawer';
// ----------------------------------------------------------------------

export default function Users() {
  const [isLoading, setIsLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [users, setUsers] = useState([]);
  const [comInfo, setComInfo] = useState([]);
  const [state, setState] = useState(false);
  const [updState, setUpdState] = useState(0);
  const handleChange = (e) => {
    const newInfo = { ...comInfo };
    newInfo[e.target.id.split('-')[0]] = e.target.value;
    setComInfo(newInfo);
  };
  const handleAssessInfo = (id, info) => {
    const newInfo = { ...comInfo };
    newInfo[id] = info;

    setComInfo(newInfo);
  };
  const onSubmit = async () => {
    const body = {
      email: comInfo?.email,
      password: comInfo?.password,
      account_type: comInfo?.account_type
    };

    const isSucceed = await postRequest('registerUser', body, 'User');
    if (isSucceed) {
      setReload(!reload);
    }
  };

  const handleDelete = async (id, status) => {
    const isSucceed = await deleteRequest('deleteUser', id, 'User');
    if (isSucceed) {
      setReload(!reload);
    }
  };
  const getUsers = async () => {
    const data = await GET_API('getUsers');
    setUsers(data);
    setIsLoading(false);
  };
  useEffect(() => {
    getUsers();
  }, [reload]);

  const handleAddQsn = () => {
    setState(!state);
  };
  return (
    <Page title="Dashboard: Blog">
      <Card sx={{ p: 3 }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            Users
          </Typography>
          {/* 
          <Stack direction="row" pl={2}>
            <Button onClick={handleAddQsn} variant="outlined">
              Add User
            </Button>
          </Stack> */}
        </Stack>
        {isLoading && <CustomSkeleton hid={110} />}
        <AddUserDrawer
          onSubmit={onSubmit}
          state={state}
          setState={setState}
          handleChange={handleChange}
          handleAssessInfo={handleAssessInfo}
        />
        <UpdateUserDrawer
          state={updState ? true : false}
          comInfo={comInfo}
          usrInfo={users[updState - 1]}
          setState={setUpdState}
          getUsers={getUsers}
          handleChange={handleChange}
        />

        {users?.map((user, index) => (
          <UserCard
            key={user.id}
            idx={index}
            setState={setUpdState}
            handleDelete={handleDelete}
            user={user}
          />
        ))}
      </Card>
    </Page>
  );
}
