import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as React from 'react';
import { Edit } from '@mui/icons-material';
import {
  Box,
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Drawer,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TextField,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import avatar from './../assets/avatar_25.png'
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteRequest, getRequest, imgPostRequest, postRequest, updateRequest } from 'src/utils/api';
import toast from 'react-hot-toast';
import LoadingButton from '@mui/lab/LoadingButton';
import CustomSkeleton from 'src/components/CustomSkeleton';
const TABLE_HEAD = [
  { id: 'S.No', label: 'S.No', alignRight: false },
  { id: 'Avatar', label: 'Avatar', alignRight: false },
  { id: 'company name', label: 'Company', alignRight: false },
  { id: 'role', label: 'Email', alignRight: false },
  { id: '5', label: 'Assessment limit', alignRight: false },
  { id: '4', label: ' Assessment Taken', alignRight: false },
  { id: '3', label: 'unique id', alignRight: false },
];

const user_base_url = 'https://insight.peopleaccelsolutions.com';

export default function CreateCompany() {
  const [isLoading, setIsLoading] = useState(true);
  const [comInfo, setComInfo] = useState(0);
  const [comList, setComList] = useState([]);
  const [items, setItems] = useState([]);
  const [reload, setReload] = useState(false);
  const [imgUrl, setImgUrl] = useState(null);
  const [editCompany, setEditCompany] = useState(null);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [state, setState] = useState(false);
  const [companyType, setCompanyType] = useState(1);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      // const newSelecteds = USERLIST.map((n) => n.name);
      // setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };


  const handleComTypeChange = (event) => {

    const currentVal = event.target.value;
    setCompanyType(currentVal);
    // handleGetRequest(currentVal === 2 ? "allDeletedCompanies" : "getComWithoutReport")

  };

  const handleFilterByName = (event) => {
    const searchText = event.target.value
    const filteredItems = comList.filter(company => company?.company_name.toLowerCase().includes(searchText.toLowerCase()))
    setItems(searchText ? filteredItems : comList)
    setFilterName(searchText)
  };
  const handleChange = (e, value) => {
    const newInfo = { ...comInfo };
    newInfo[e.target.id.split('-')[0]] = e.target.value;
    setComInfo(newInfo);
  }
  const onSubmit = async () => {
    if (!imgUrl) {
      return toast.error("Please upload your image. try again")
    }
    else if (!comInfo.company_name || !comInfo.email) {
      return toast.error(`Please enter your company ${!comInfo.company_name ? "name" : "email"}`)
    }
    else if (!comInfo.noOfAssessment) {
      return toast.error("Please upload no of assessment you want. try again")
    }
    const newComName = comInfo.company_name.replace(/\s+/g, '-').toLowerCase()
    const body = {
      "name": comInfo.name,
      "company_name": newComName,
      "email": comInfo.email,
      "img_url": imgUrl,
      "no_of_assessment": comInfo.noOfAssessment,
      "unique_id": `${user_base_url}/${newComName}/home`,
    }
    const isSucceed = await postRequest("addCompany", body, "Company")
    if (isSucceed) {
      setReload(!reload)
      setState(false)
    }
  }
  const handleDelete = async (company) => {
    const route = company.is_deleted ? "deleteCompanyPermanently" : "deleteCompany"
    const isSucceed = await deleteRequest(route, company.id, "Company")
    if (isSucceed) { setReload(!reload) }
  }
  const handleEdit = async (company) => {
    setEditCompany(company)
    setState(true)
    setImgUrl(company.img_url)
  }
  const handleUpdate = async (company) => {
    const newComName = comInfo?.company_name?.replace(/\s+/g, '-')?.toLowerCase()
    const body = {
      "name": comInfo?.name,
      "company_name": newComName,
      "email": comInfo?.email,
      "img_url": imgUrl,
      "no_of_assessment": comInfo?.noOfAssessment,
      "unique_id": `${user_base_url}/${newComName}/home`,
    }
    const conditionalBody = company.is_deleted ? { "is_deleted": false } : body
    const isSucceed = await updateRequest(`updateCompany/${company.id}`, conditionalBody, "Company")
    if (isSucceed) { setReload(!reload) }
  }
  const handleDrawer = async () => {
    setImgUrl(null)
    setEditCompany(null)
    setState(true)
  }

  const handleImgUpload = async (img) => {
    setLoading(true)
    const isSucceed = await imgPostRequest(img)
    if (isSucceed) { setImgUrl(isSucceed) }
    setLoading(false)
  }

  const handleGetRequest = async (route) => {
    const data = await getRequest(route)
    setComList(data)
    setItems(data)
    setIsLoading(false)
  }
  useEffect(() => {
    handleGetRequest(companyType === 2 ? "allDeletedCompanies" : "getComWithoutReport")
  }, [reload, companyType])

  const handleClipboard = async (comName) => {
    navigator.clipboard.writeText(`${user_base_url}/${comName}/home`);
  }
  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const delBtnStyle1 = { paddingRight: "5px", minWidth: "20px" }
  const delBtnStyle2 = { paddingRight: "5px", minWidth: "20px", paddingLeft: "5px", fontSize: "13px" }
  const editBtnStyle1 = { color: "green", paddingRight: "5px", minWidth: "20px", }
  const editBtnStyle2 = { color: "green", padding: "15px", minWidth: "20px", fontSize: "10px" }
  // sx={{ marginLeft: "10px", marginRight: "10px", color: "green", paddingRight: "5px", minWidth: "20px" }}
  const tobBox = { display: "flex", justifyContent: "space-between" }

  return (
    <Page title="User ">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Company Create
          </Typography>
          <Button
            onClick={() => handleDrawer()}
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add Company
          </Button>
        </Stack>
        <>
          <div>
            <Drawer
              anchor='right'
              open={state}
              onClose={() => setState(false)}
            >
              <Stack alignItems="center" justifyContent="center" mb={3}>
                <Button
                  sx={{ "&:hover": { backgroundColor: "transparent" } }}
                  component="label"
                >
                  <img style={{ width: "150px", height: "150px", borderRadius: "50%" }} src={imgUrl ? imgUrl : avatar} alt="logo" />
                  <input
                    type="file"
                    onChange={(e) => handleImgUpload(e.target.files[0])}
                    hidden
                  />
                </Button>
                <LoadingButton
                  onClick={editCompany ? () => handleUpdate(editCompany) : () => onSubmit()}
                  loading={loading}
                  sx={{
                    position: 'absolute',
                    marginTop: '10px',
                    marginRight: '10px',
                    right: '0',
                    top: '0',
                  }} variant="outlined" > {editCompany ? "Update" : " Save"}</LoadingButton>
              </Stack>
              <Box
                sx={{
                  width: 450, p: 2, display: 'grid',
                  gap: 2,
                  gridTemplateColumns: 'repeat(1, 1fr)',
                }}
                role="presentation"
              >
                <TextField
                  onBlur={(e, value) => handleChange(e, value)}
                  id="name"
                  required
                  label={editCompany ? editCompany.name : "Name"}
                  placeholder="Name"
                />
                <TextField
                  onBlur={(e, value) => handleChange(e, value)}
                  id="company_name"
                  required
                  label={editCompany ? editCompany.company_name : "Company Name"}
                  placeholder="Company Name"
                />
                <TextField
                  onBlur={(e, value) => handleChange(e, value)}
                  id="email"
                  required
                  label={editCompany ? editCompany.email : "Email"}
                  placeholder="Email"
                />
                <TextField
                  required
                  onBlur={(e, value) => handleChange(e, value)}
                  id="noOfAssessment"
                  label={editCompany ? editCompany?.noOfAssessment : "No of assessment"}
                  placeholder="No of assessment"
                />
              </Box>
            </Drawer>
          </div>
          <Card>
            <Box style={tobBox}>
              <UserListToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
              />
              <FormControl variant="standard" sx={{ minWidth: 140, mr: 4, mt: 4 }}>
                <InputLabel id="demo-custom-label">Company Status</InputLabel>
                <Select
                  labelId="demo-custom-label"
                  id="demo-custom"
                  defaultValue={1}
                  onChange={handleComTypeChange}
                  label="Active"
                >
                  <MenuItem value={1}>Active</MenuItem>
                  <MenuItem value={2}>Archived</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    // rowCount={USERLIST.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  {isLoading ? null :
                    <TableBody>
                      {items?.map((company, index) => {
                        const isItemSelected = selected.indexOf(company?.name) !== -1;
                        return (
                          <TableRow
                            hover
                            key={company?.id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >

                            <TableCell component="th" scope="row" sx={{ px: 3 }}>
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {index + 1}
                                </Typography>

                              </Stack>
                            </TableCell>
                            <TableCell align="left">  <Avatar alt={company?.name} src={company?.img_url} /></TableCell>
                            <TableCell align="left">{company?.company_name}</TableCell>
                            <TableCell align="center">{company?.email}</TableCell>
                            <TableCell align="center">{company?.no_of_assessment}</TableCell>
                            <TableCell align="center">{company?.report_count || 0}</TableCell>
                            <TableCell align="left">
                              <IconButton
                                aria-label="toggle password visibility"
                                edge="end"
                                onClick={() => handleClipboard(company?.company_name)}
                              >
                                <Iconify icon="akar-icons:copy" />
                              </IconButton>
                            </TableCell>
                            <TableCell align="right">
                              <Stack direction="row" spacing={2}>
                                <Button
                                  sx={company?.is_deleted ? delBtnStyle2 : delBtnStyle1}
                                  onClick={() => handleDelete(company)}
                                  variant="outlined" color="error" startIcon={!company?.is_deleted && <DeleteIcon />}>

                                  {company?.is_deleted ? "Delete Permanently" : null}
                                </Button>
                                <Button
                                  onClick={() => company?.is_deleted ? handleUpdate(company) : handleEdit(company)}
                                  variant="outlined"
                                  sx={company?.is_deleted ? editBtnStyle2 : editBtnStyle1}

                                  startIcon={!company?.is_deleted && <Edit />}>
                                  {company?.is_deleted ? "restore" : null}
                                </Button>
                              </Stack>
                              {/* <UserMoreMenu company={company} handleDelete={handleDelete} setState={setState} /> */}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {/* {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )} */}
                    </TableBody>}
                  {!isLoading && items.length < 1 && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              {isLoading ? <CustomSkeleton hid={80} /> : null}
            </Scrollbar>
          </Card>
        </>
      </Container>
    </Page >
  );
}