import swal from 'sweetalert';
import toast from 'react-hot-toast';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { useState, useEffect } from 'react';
import { Grid, Button, Stack, Typography, Card, TextField, Drawer, Checkbox, Autocomplete } from '@mui/material';
// components
import Page from '../components/Page';
import CustomSkeleton from 'src/components/CustomSkeleton';
import CompanyList from '../components/CompanyList';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import PlanningCard from 'src/components/PlanningCard';
import { assessPostRequest, deleteRequest, getRequest, imgPostRequest, postRequest } from 'src/utils/api';
import Reset from 'src/components/Reset';
import pdfImg from '../assets/pdf-icon (2).png'
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
// ----------------------------------------------------------------------
export default function Planning() {
  const [isLoading, setIsLoading] = useState(true);
  const [comList, setComList] = useState([]);
  const [allPlannings, setPlannings] = useState([]);
  const [assessPlan, setAssessPlan] = useState(null);
  const [imgUrl, setImgUrl] = useState(null);
  const [comInfo, setComInfo] = useState([])
  const [loading, setLoading] = useState(false);
  const [checking, setChecking] = useState(false);
  const [reload, setReload] = useState(false);
  const [reloadAssess, setReloadAssess] = useState(false);
  const [state, setState] = useState(false);
  const [planningMod, setPlanningMod] = useState(false);

  const handleAssessInfo = (value) => {
    const newInfo = { ...comInfo };
    newInfo["planning_info"] = value;

    setComInfo(newInfo);
  }


  const handleImgSave = async () => {
    if (!imgUrl) {
      return toast.error('Please upload the img first...!');
    }
    const body = { img_url: imgUrl }
    const isSucceed = await postRequest("addPlanning", body, "Planning")
    if (isSucceed) { setReload(!reload) }
  }
  const handlePlanningAssessment = async () => {
    const urlList = comInfo.planning_info.map(item => item.img_url)
    const body = { planning_assessment: urlList }
    const isSucceed = await assessPostRequest("addPlanAssesInfo", comInfo.company_id, body, "Planning")
    if (isSucceed) {
      setReloadAssess(!reloadAssess)
      setState(false)
    };


  }
  const handleImgUpload = async (img) => {
    setLoading(true)
    const isSucceed = await imgPostRequest(img)
    if (isSucceed) {
      setImgUrl(isSucceed);
      setLoading(false)
    }
    else { setLoading(false) }
  }
  const handleDelete = async (id) => {
    const isSucceed = await deleteRequest("deletePlanning", id, "Planning Info")
    if (isSucceed) {
      setReload(!reload)
    }
  }
  const handleAssessDelete = async () => {
    const isSucceed = await deleteRequest("deletePlanAssessInfo", assessPlan.id, "Planning Info")
    if (isSucceed) { setReloadAssess(!reloadAssess) };

  }
  const checkCompanySelector = () => {
    setPlanningMod(false)
    if (comInfo.company_id && !assessPlan) {
      setState(!false)
    }
    else if (assessPlan) {
      return swal("Failed!", "Reset first  to add assessment info again.", "error", { dangerMode: true });
    }
    else {
      swal("Failed!", "Please select a Company and  try again.", "error", { dangerMode: true });
    }
  }
  const openModal = () => {
    setPlanningMod(true)
    setState(true)

  }
  const handleGetRequest = async (route) => {
    setChecking(true)
    const data = await getRequest(route)
    setAssessPlan(data?.data[0]?.assess_planning)
    setChecking(false)
  }
  useEffect(() => {
    if (comInfo?.company_id) {
      handleGetRequest(`getCompanyById/${comInfo?.company_id}`)
    }
  }, [comInfo?.company_id, reloadAssess])
  const handleGetPlanningRequest = async () => {
    const data = await getRequest("getPlanning")
    setPlannings(data.data)
    setIsLoading(false)
  }
  useEffect(() => {
    handleGetPlanningRequest()
  }, [reload])
  return (
    <Page title="Getting Started">
      <Card sx={{ p: 3, overflow: "inherit" }}>
        <Grid container spacing={2}>
          
          <Grid item xs={3}>
            <Typography variant="h4" gutterBottom>
              Monthly planning
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Reset
              assessInfo={assessPlan}
              handleAssessDelete={handleAssessDelete}
              loading={checking}
              checkCompanySelector={checkCompanySelector}
            />


          </Grid>

          <Grid item xs={4}>
            <CompanyList
              comInfo={comInfo}
              setComInfo={setComInfo}
              comList={comList}
              setComList={setComList} />
          </Grid>
          <Stack direction="row" justifyContent="end" pl={2}>
            <Button
              onClick={openModal}
              variant="outlined"
            >Add Planning
            </Button>
          </Stack>
        </Grid>
        {isLoading ? <CustomSkeleton hid={110} /> :
          <Drawer
            anchor='right'
            open={state}

            onClose={() => setState(false)}
          >
            <Stack
              sx={{ width: 450, mt: 2 }}
              alignItems="center" justifyContent="center" mb={3}>

              {planningMod ?
                <>
                  <img style={{ width: "150px", height: "150px" }} src={imgUrl ? imgUrl : pdfImg} alt="logo" />
                  <Button sx={{ my: 2 }} variant="contained" component="span">

                    <input
                      style={{ backgroundColor: "transparent", border: "none" }}
                      type="file"
                      onChange={(e) => handleImgUpload(e.target.files[0])}

                    />
                  </Button>
                </> :


                <Autocomplete
                  onChange={(event, newValue) => {
                    handleAssessInfo(newValue);
                  }}
                  multiple
                  options={allPlannings}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.img_url.split('/')[4]}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}

                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.img_url.split('/')[4]}
                    </li>
                  )}
                  style={{ width: 420 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Planning" placeholder="Favorites" />
                  )}
                />
              }
              <LoadingButton

                sx={{ mt: 2, width: "70%" }}
                onClick={planningMod ? handleImgSave : handlePlanningAssessment}
                color="success"
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="outlined"
              >{"Save"}</LoadingButton>
            </Stack>

          </Drawer>}
        {allPlannings?.map(planning =>
          <PlanningCard
            key={planning.id}
            handleDelete={handleDelete}
            planning={planning}
          />)}
      </Card>
    </Page >
  );
}
