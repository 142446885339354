import React from "react";
import { Page, Document, StyleSheet } from "@react-pdf/renderer";
import ItemsTable from "./ItemsTable";

const Table = ({ data, headers, type }) => (
    <Document>
        <ItemsTable
            data={data}
            headers={headers}
            type={type} />
    </Document>
);

export default Table;