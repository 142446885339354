import DeleteIcon from '@mui/icons-material/Delete';
import GridViewIcon from '@mui/icons-material/GridView';
import { Box, Button, Card, Typography } from '@mui/material';

export default function UserCard({ handleDelete, user, setState, idx }) {
  const { account_type, email, id } = user;
  return (
    <Card sx={{ p: 3, mt: 3, backgroundColor: '#F8F9FA' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h6">{email}</Typography>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Button
            disabled={account_type === 'ADMIN' ? true : false}
            variant="outlined"
            onClick={() => handleDelete(id, false)}
            color="error"
            startIcon={<DeleteIcon />}
          >
            DELETE
          </Button>

          <Button
            // disabled={account_type === 'ADMIN' ? true : false}
            onClick={() => setState(idx + 1)}
            variant="outlined"
            color="success"
            startIcon={<GridViewIcon />}
          >
            Reset
          </Button>
        </Box>
      </Box>
    </Card>
  );
}
