import { Box, Button, Drawer, Stack, TextField } from '@mui/material';
import React from 'react';
import SingleSelector from '../SingleSelector';

const AddUserDrawer = ({ onSubmit, state, setState, handleChange, handleAssessInfo }) => {
  const boxStyle = {
    width: 450,
    p: 2,
    mt: 3,
    display: 'grid',
    gap: 2,
    marginTop: '10px',
    gridTemplateColumns: 'repeat(1, 1fr)'
  };
  return (
    <Drawer anchor="right" open={state} onClose={() => setState(false)}>
      <Box sx={boxStyle} role="presentation">
        <Stack alignItems="center">
          <TextField
            onBlur={(e, value) => handleChange(e, value)}
            id="email"
            sx={{ width: '100%', marginBottom: '10px' }}
            label="User Email"
            placeholder="User Email"
          />
          <TextField
            onBlur={(e, value) => handleChange(e, value)}
            id="password"
            sx={{ width: '100%', marginBottom: '10px' }}
            label="password"
            placeholder="password"
          />
          <SingleSelector
            handleAssessInfo={handleAssessInfo}
            options={['Admin', 'User']}
            label="User Type"
            id="account_type"
          />
        </Stack>
        <Stack alignItems="center" justifyContent="center">
          <Button onClick={() => onSubmit()} variant="outlined" color="success">
            Save
          </Button>
        </Stack>
      </Box>
    </Drawer>
  );
};

export default AddUserDrawer;
