import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';
import React from 'react';

const Reset = ({ assessInfo, handleAssessDelete, loading, checkCompanySelector }) => {
    return (
        <Box display="flex" alignItems="center" justifyContent="end">
            {assessInfo &&
                <Button
                    onClick={() => handleAssessDelete(assessInfo?.id)}
                    style={{ marginRight: 10 }}
                    size="large"
                    color="error"
                    variant="outlined"
                >Reset
                </Button>}
            {loading ?
                <LoadingButton loading size="large" variant="outlined">
                    Submit
                </LoadingButton  > :

                <Button
                    onClick={checkCompanySelector}
                    color="secondary"
                    size="large"
                    variant="outlined"
                >Edit Content</Button>}
        </Box>
    );
};

export default Reset;