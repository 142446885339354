import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  Card,
  Table,
  Stack,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  IconButton
} from '@mui/material';

// components
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// import USERLIST from '../_mocks_/user';
import { LoadingButton } from '@mui/lab';
import Pdf from 'src/components/Pdf';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReportDetails from 'src/components/ReportDetails';
import IndividualReport from 'src/components/IndividualReport';
import CustomSkeleton from 'src/components/CustomSkeleton';
import toast from 'react-hot-toast';
import { deleteRequest, getReportById, getRequest, GET_API } from 'src/utils/api';
const TABLE_HEAD = [
  { id: 'S.No', label: 'S.No', alignRight: false },
  { id: 'Company', label: 'Company', alignRight: false },
  { id: 'name', label: 'company name', alignRight: false },
  { id: 'role', label: 'Email Id', alignRight: false },
  { id: 'isVerified', label: '', alignRight: false }
];

export default function Reports() {
  const [isLoading, setIsLoading] = useState(true);
  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [reports, setReports] = useState([]);
  const [individualReports, setIndividualReports] = useState([]);
  // const [individualReportsId, setIndividualReportsId] = useState([]);
  const [subReport, setSubReport] = useState([]);
  const [subReportType, setSubReportType] = useState('');
  const [items, setItems] = useState([]);
  const [reportContent, setReportContent] = useState({});
  const [reportId, setReportId] = useState(null);
  const [subReportId, setSubReportId] = useState(null);
  // const [reload, setReload] = useState(false);
  const [pdfCnt, setPdfCnt] = useState([]);
  const [open, setOpen] = React.useState([]);
  const reportsType = ['Industry', 'Location', 'Department', 'Role'];
  const handleCollapsableRow = async (i, id = null) => {
    if (open[0] !== id) {
      const loading = toast.loading('Please wait...!');
      // GET_API()
      const reports = await getReportById(`gerReportById/${id}`);
      toast.dismiss(loading);
      setIndividualReports(reports.data[0].reports);
    } else {
      setIndividualReports([]);
    }
    // setSubReport([])
    setReportId(null);
    if (open.includes(i)) {
      setOpen([]);
    } else {
      setOpen([i]);
    }
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleChange = (e, value) => {
    const type = e.target.innerText;
    setSubReportType(type);
    mergedReportsHandler(value, type);
  };
  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     // const newSelecteds = USERLIST.map((n) => n.company_name);
  //     // setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };
  const handleFilterByName = (event) => {
    const searchText = event.target.value;
    const filteredItems = reports.filter((company) =>
      company?.company_name.toLowerCase().includes(searchText.toLowerCase())
    );
    setItems(searchText ? filteredItems : reports);
    setFilterName(searchText);
  };

  const handleGetRequest = async () => {
    const data = await getRequest('getComWithoutReport');
    setReports(data);
    setItems(data);
    setIsLoading(false);
  };
  useEffect(() => {
    handleGetRequest();
  }, []);
  const handleReportDelete = async (id, e) => {
    const isSucceed = await deleteRequest('deleteReports', id, 'Report');
    if (isSucceed) {
      e.target.parentNode.parentNode.style.display = 'none';
    }

    // const data = await getRequest("getReportContent")
    // setPdfCnt(data[0])
  };
  const handleContentGetRequest = async () => {
    const data = await getRequest('getReportContent');
    setPdfCnt(data[0]);
  };
  useEffect(() => {
    handleContentGetRequest();
  }, []);
  const mergedReportsHandler = (id, type) => {
    let uniqueReport = [];
    reports.map((report) => {
      let index;
      switch (type) {
        case 'Industry':
          index = uniqueReport.findIndex((items) => items.industry === report.industry);
          break;
        case 'Location':
          index = uniqueReport.findIndex((items) => items.location === report.location);
          break;
        case 'Department':
          index = uniqueReport.findIndex((items) => items.department === report.department);
          break;
        default:
          index = uniqueReport.findIndex((items) => items.role === report.role);
      }
      if (index === -1) {
        uniqueReport.push({
          role: report.role,
          department: report.department,
          industry: report.industry,
          location: report.location,
          id: report.id,
          happiness_score: report.happiness_score,
          selectionTime: 1,
          target_submission: report.target_submission[0]?.valueRanking,
          current_submission: report.current_submission[0]?.valueRanking,
          answered_qsn: handleQsnSelection(report.answered_qsn)
        });
      } else {
        uniqueReport[index].happiness_score += report.happiness_score;
        uniqueReport[index].selectionTime++;
        uniqueReport[index].target_submission = handleSubmission(
          report.target_submission[0],
          uniqueReport[index].target_submission
        );
        uniqueReport[index].current_submission = handleSubmission(
          report.current_submission[0],
          uniqueReport[index].current_submission
        );
        uniqueReport[index].answered_qsn = handleAnsQuestion(
          report.answered_qsn,
          uniqueReport[index].answered_qsn
        );
      }
      return 0;
    });
    setSubReport(uniqueReport);
  };

  const handleSubmission = (currentReport, submittedReport) => {
    const newItems = [];
    currentReport?.valueRanking?.map((currentItem) => {
      let isMatched = false;
      for (let i = 0; i < submittedReport.length; i++) {
        const preItem = submittedReport[i];
        if (currentItem.id === preItem.id) {
          if (currentItem.mLikely) {
            submittedReport[i].mLikely++;
          } else if (currentItem.lLikely) {
            submittedReport[i].lLikely++;
          }
          isMatched = true;
          break;
        }
      }
      if (!isMatched) {
        newItems.push(currentItem);
      }
      return 0;
    });
    const newRtn = [...submittedReport, ...newItems];
    // console.log("return able", newRtn);
    return newRtn;
  };
  const handleQsnSelection = (questions) => {
    for (let i = 0; i < questions.length; i++) {
      const element = questions[i].data;
      for (let j = 0; j < element.length; j++) {
        const qsn = element[j];
        questions[i].data[j].options[qsn.selected - 1].selected = 1;
        questions[i].data[j].noOfSel = 1;
      }
    }
    return [...questions];
  };
  const handleAnsQuestion = (currentReport, submittedReport) => {
    const newItems = [];
    currentReport.map((currentItem) => {
      for (let i = 0; i < submittedReport.length; i++) {
        const preItem = submittedReport[i];
        if (currentItem.question === preItem.question) {
          for (let j = 0; j < preItem.data.length; j++) {
            const preElement = preItem.data[j];
            if (!currentItem.data[j]?.selected) {
              console.log(currentItem, 'currentItem.data', preItem, 'preItem.data');
              continue;
            }
            const currentElementOp = currentItem.data[j]?.selected;
            const hasSelected = preElement.options[currentElementOp - 1].selected;

            if (!hasSelected) {
              preElement.options[currentElementOp - 1].selected = 1;
              preElement.noOfSel++;
            } else {
              preElement.options[currentElementOp - 1].selected++;
              preElement.noOfSel++;
              const curSelectedOption = preElement.options[currentElementOp - 1].selected;
              const preSelectedOption = preElement.options[preElement.selected - 1].selected;
              if (curSelectedOption > preSelectedOption) {
                preElement.selected = currentElementOp;
              }
            }
          }
          break;
        } else if (currentItem.question !== preItem.question && submittedReport.length === i + 1) {
          for (let j = 0; j < currentItem.data.length; j++) {
            const qsn = currentItem.data[j];
            currentItem.data[j].options[qsn.selected - 1].selected = 1;
            currentItem.data[j].noOfSel = 1;
          }
          newItems.push(currentItem);
        }
      }
      return 0;
    });
    const newRtn = [...submittedReport, ...newItems];
    return newRtn;
  };
  const generateReport = async (id, individualReport = null) => {
    let initialReport;
    let resReplicas;
    if (!individualReport) {
      const loading = toast.loading('Please wait...!');
      const res = await getReportById(`gerReportById/${id}`);
      console.log('res', res);
      resReplicas = res?.replicas;
      initialReport = res.data[0].reports;
      toast.dismiss(loading);
    } else {
      initialReport = individualReport;
    }

    const newObj = {
      happiness_score: 0,
      no_of_report: reports?.length,
      target_submission: [],
      current_submission: [],
      answered_qsn: []
    };

    initialReport.map((report, index) => {
      if (index === 0) {
        newObj.happiness_score += report.happiness_score;
        newObj.target_submission = report.target_submission[0]?.valueRanking;
        newObj.current_submission = report.current_submission[0]?.valueRanking;
        newObj.answered_qsn = handleQsnSelection(report.answered_qsn);
      } else {
        const newScore = newObj.happiness_score * index;
        newObj.happiness_score = (report.happiness_score + newScore) / (index + 1);
        newObj.target_submission = handleSubmission(
          report.target_submission[0],
          newObj.target_submission
        );
        newObj.current_submission = handleSubmission(
          report.current_submission[0],
          newObj.current_submission
        );
        newObj.answered_qsn = handleAnsQuestion(report.answered_qsn, newObj.answered_qsn);
      }
      return 0;
    });
    if (!individualReport) {
      const defaultCategories = [
        'Structuring',
        'Cultural Fit',
        'Competency Development',
        'Process Standardization',
        'Customer Engagement',
        'Management Capacity',
        'Profitability',
        'Safety and Security',
        'Post COVID'
      ];
      const replicas = resReplicas ? resReplicas : defaultCategories;
      for (const [index, replica] of newObj.answered_qsn.entries()) {
        const expIdx = replicas.indexOf(replica.question);
        if (expIdx >= 0) {
          const temp = newObj.answered_qsn[expIdx];
          newObj.answered_qsn[expIdx] = replica;
          newObj.answered_qsn[index] = temp;
        }
      }
    }
    setReportContent(newObj);
    setReportId(id);
  };
  return (
    <Page title="User ">
      <Container>
        <Card sx={{ p: 3 }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h4" gutterBottom>
              Published Report
            </Typography>
          </Stack>
          <UserListToolbar filterName={filterName} onFilterName={handleFilterByName} />
          <Scrollbar>
            <TableContainer>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  onRequestSort={handleRequestSort}
                />
                {isLoading ? null : (
                  <TableBody>
                    {items.map((report, index) => {
                      const { id, email, company_name, img_url } = report;
                      return (
                        <>
                          <TableRow hover key={id} tabIndex={-1} role="checkbox">
                            <TableCell component="th" scope="row" sx={{ px: 3 }}>
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {index + 1}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">
                              {' '}
                              <Avatar alt={company_name} src={img_url} />
                            </TableCell>
                            <TableCell align="left">{company_name}</TableCell>
                            <TableCell align="left">{email}</TableCell>
                            <TableCell align="center">
                              {reportId === id ? (
                                <PDFDownloadLink
                                  document={
                                    <Pdf
                                      pdfCnt={pdfCnt}
                                      item={reportContent}
                                      img={img_url}
                                      email={email}
                                      name={company_name}
                                    />
                                  }
                                  fileName="FORM"
                                >
                                  {({ loading, error }) =>
                                    !loading ? (
                                      <IconButton color="success">
                                        <FileDownloadOutlinedIcon />
                                      </IconButton>
                                    ) : (
                                      <LoadingButton loading variant="outlined">
                                        Submit
                                      </LoadingButton>
                                    )
                                  }
                                </PDFDownloadLink>
                              ) : (
                                <IconButton onClick={() => generateReport(id)}>
                                  <FileDownloadOutlinedIcon />
                                </IconButton>
                              )}
                            </TableCell>
                            {/* <TableCell align="right">
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  onClick={() => handleCollapsableRow(index, id)}
                                >
                                  {open.includes(index) ? (
                                    <KeyboardArrowUpIcon />
                                  ) : (
                                    <KeyboardArrowDownIcon />
                                  )}
                                </IconButton>
                              </TableCell> */}
                            <TableCell align="right">
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => handleCollapsableRow(id, id)}
                              >
                                {open.includes(id) ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowDownIcon />
                                )}
                              </IconButton>
                            </TableCell>
                          </TableRow>
                          <ReportDetails
                            setSubReportId={setSubReportId}
                            subReportId={subReportId}
                            index={index}
                            open={open}
                            individualReports={individualReports}
                            pdfCnt={pdfCnt}
                            handleChange={handleChange}
                            reportsType={reportsType}
                            report={report}
                            subReportType={subReportType}
                            subReport={subReport}
                          />
                          <IndividualReport
                            id={id}
                            handleReportDelete={handleReportDelete}
                            open={open}
                            pdfCnt={pdfCnt}
                            generateReport={generateReport}
                            reportId={reportId}
                            reportContent={reportContent}
                            report={report}
                            individualReports={individualReports}
                          />
                        </>
                      );
                    })}
                  </TableBody>
                )}

                {!isLoading && items.length < 1 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            {isLoading ? <CustomSkeleton hid={80} /> : null}
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}
