import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import TableRow from "./TableRow";
import TableHeader from "./TableHeader";
import DiagnosisRow from "./DiagnosisRow";

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        border: "1px solid gray",


    },
});

const ItemsTable = ({ data, headers, type }) => (
    <View style={styles.tableContainer}>
        <TableHeader headers={headers} type={type} />
        {type ? <TableRow items={data} /> : <DiagnosisRow items={data} />}

        {/*<TableFooter items={data.items} />*/}
    </View>
);

export default ItemsTable;