import toast from 'react-hot-toast';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { Link as useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Card, Container, Typography, Box } from '@mui/material';
import Page from '../components/Page';
import { LoginForm } from '../sections/authentication/login';
import { rootUrl } from 'src/utils/api';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '40%',
  // maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundImage: 'url(/static/illustrations/login_bg.png)',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  const navigate = useNavigate();
  const [logState, setLogState] = useState(false);
  const handleLogin = (info) => {
    const loading = toast.loading('Please wait...!');
    fetch(`${rootUrl}login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/Json'
      },
      body: JSON.stringify({
        email: info.email,
        password: info.password
      })
    })
      .then((response) => response.json())
      .then((data) => {
        toast.dismiss(loading);

        localStorage.setItem(
          'loginInfo',
          JSON.stringify({ token: data?.access_token, email: data.user.email })
        );

        if (!data.error) {
          navigate('/dashboard/create-company', { replace: true });
          toast.success(' logged in Successfully!');
          return;
        }
        setLogState(true);
        toast.error('Something went wrong! Please try again.');
      })
      .catch((error) => {
        toast.dismiss(loading);
        setLogState(true);
        toast.error('Something went wrong! Please try again.');
      });
  };
  return (
    <>
      <RootStyle title="Login">
        <SectionStyle sx={{ display: { xs: 'none', md: 'flex' } }}></SectionStyle>
        <Container maxWidth="sm">
          <ContentStyle>
            <Box sx={{ mb: 5 }}>
              <Typography variant="h4" gutterBottom>
                Welcome Back
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                Enter your email and password to sign in
              </Typography>
            </Box>
            <LoginForm handleLogin={handleLogin} logState={logState} />
          </ContentStyle>
        </Container>
      </RootStyle>
    </>
  );
}
