import { Box, Button, Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { PDFDownloadLink } from '@react-pdf/renderer';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import Pdf from './Pdf';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { LoadingButton } from '@mui/lab';
const IndividualReport = (props) => {
    const { id, open, generateReport, reportId, reportContent, report, pdfCnt, individualReports, handleReportDelete } = props

    return (
        <TableRow >
            <TableCell
                style={{ paddingBottom: 0, paddingTop: 0 }}
                colSpan={6}
                padding="checkbox"
            >
                <Collapse
                    in={open.includes(id)}
                    timeout="auto"
                    unmountOnExit
                    id="panel1a-header"
                >
                    <Box sx={{ margin: 1 }}>
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography gutterBottom component="div" variant="h6">
                                Details Reports  by Individual
                            </Typography>

                        </Box>
                        <Table size="small" aria-label="purchases" >
                            <TableHead>
                                <TableRow>

                                    <TableCell

                                        align="center">
                                        Serial No
                                    </TableCell>
                                    <TableCell

                                        align="center">
                                        Roles
                                    </TableCell>
                                    <TableCell

                                        align="center">
                                        Happiness Factor
                                    </TableCell>
                                    <TableCell

                                        align="center">
                                        Download
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {individualReports?.map((item, index) =>
                                <TableBody key={item.id}>
                                    <TableRow>
                                        <TableCell
                                            align="center">
                                            {index + 1}
                                        </TableCell>
                                        <TableCell
                                            align="center">

                                            {item?.role}
                                        </TableCell>
                                        <TableCell
                                            align="center">
                                            {item?.happiness_score}
                                        </TableCell>
                                        <TableCell align="center">
                                            {reportId === item.id ?
                                                <PDFDownloadLink document={
                                                    <Pdf
                                                        pdfCnt={pdfCnt}
                                                        item={reportContent}
                                                        img={report?.img_url}
                                                        email={report.email}
                                                        name={report.company_name}
                                                        subReportTypes={item?.role}

                                                    />} fileName="FORM">
                                                    {({ loading, error }) => (!loading ? <IconButton color="success">
                                                        <FileDownloadOutlinedIcon />
                                                    </IconButton> : <LoadingButton loading variant="outlined">
                                                        Submit
                                                    </LoadingButton>)}
                                                </PDFDownloadLink> :
                                                <IconButton onClick={() => generateReport(item.id, [item])}>
                                                    <FileDownloadOutlinedIcon />
                                                </IconButton>}

                                        </TableCell>
                                        <TableCell align="center">
                                            <Button onClick={(e) => handleReportDelete(item.id,e)} variant="outlined" color="error" startIcon={<DeleteIcon />}>
                                                DELETE
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>

                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    );
};

export default IndividualReport;