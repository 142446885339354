import { Box, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Iconify from './Iconify';

const MultipleInput = ({ setOptionInfo, optionInfo, label, reload, editQsn }) => {
    const [option, setOption] = useState([1]);
    const [optionValue, setOptionValue] = useState([]);
    const [isLoaded, setLoaded] = useState(false);
    const btnBox = {
        position: 'relative',
    }
    const btn = {
        position: 'absolute',
        right: '0',
        bottom: '0',
        width: '100%',
        transform: 'translateY(70%)',
        cursor: 'pointer'
    }
    const handleOptionValue = (e, value, item) => {

        // const newInfos = { value: item, option: e.target.value };

        // if (stm) {
        //     const newInfo = { value: item, option: e.target.value };
        //     setOptionInfo([...optionInfo, newInfo]);
        //     console.log([...optionInfo, newInfo], "[...optionInfo, newInfo]")
        // }
        // else {
        const newInfo = { ...optionInfo };
        newInfo['' + item] = e.target.value;
        setOptionInfo(newInfo);

    }
    useEffect(() => {
        if (!editQsn) {
            setOption([1])
            setLoaded(true)

        } else {
            let optionIdx = []
            let optionInfo = {}
            for (let i = 0; i < editQsn?.options.length; i++) {
                optionInfo['' + (i + 1)] = editQsn?.options[i].option;
                optionIdx.push(i + 1)
            }
            setOptionInfo(optionInfo);
            setOptionValue(editQsn?.options)
            setOption(optionIdx)
            setLoaded(true)
        }

    }, [reload])
    const handleDelete = (id) => {
        const newOptions = option.filter(op => op !== id)
        delete optionInfo[id];
        setOption(newOptions)

    }
    return (
        <>{isLoaded ?
            option.map((item, index) =>
                <Box style={btnBox} key={index + 1}>
                    {option.length - 1 === option.lastIndexOf(item) ?
                        <Iconify onClick={() => setOption([...option, item + 1])} style={btn} color="green" id="plus-btn" icon="akar-icons:circle-plus-fill" /> :
                        editQsn ?
                            <Iconify onClick={() => handleDelete(item)} style={btn} color="red" icon="charm:circle-cross" /> : null
                    }

                    <TextField
                        onBlur={(e, value) => handleOptionValue(e, value, item)}
                        id={`options+${item}`}
                        sx={{ marginTop: 5 }}
                        style={{ width: '100%', marginTop: 15, marginBottom: 5 }}
                        defaultValue={`${optionValue[item - 1] ? optionValue[item - 1]?.option : ""}`}
                        label={`${optionValue[item - 1] ? optionValue[item - 1]?.option : label}`}
                    />
                </Box>
            )
            : null}</>
    );
};

export default MultipleInput;