import swal from 'sweetalert';
// material
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useState, useEffect } from 'react';
import { Grid, Button, Stack, Typography, Box, TextField, Autocomplete, Drawer, Checkbox, FormGroup, FormControlLabel, Card } from '@mui/material';
import CompanyList from '../CompanyList';
import CustomAutocomplete from '../CustomAutocomplete';
import { assessPostRequest, deleteRequest, getRequest, GET_API } from 'src/utils/api';
import Reset from '../Reset';
import QsnByCategory from './QsnByCategory';
// components
// ----------------------------------------------------------------------
export default function AddCompnayInfo({ industries, department, roles, location }) {
    const [comList, setComList] = useState([]);
    const [comInfo, setComInfo] = useState([])
    const [assessInfo, setAssessInfo] = useState(null);
    const [allIndustries, setIndustries] = useState([])
    const [qsnsId, setQsnsId] = useState([])
    const [allDepartment, setAllDepartment] = useState([])
    const [allLocation, setAllLocation] = useState([])
    const [questions, setQuestions] = useState([])
    const [currentCtgry, setCrnCtgry] = useState([])
    const [reload, setReload] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isRolesChanged, setRolesChanged] = useState(false)
    const [state, setState] = useState(false);
    const handleAssessInfo = (id, info) => {
        const newInfo = { ...comInfo };
        newInfo[id] = info;
        setComInfo(newInfo);
    }
    const roleBaseQsnHandler = (resInfo, lastRole) => {
        let newQsnByCtgry = []
        resInfo.map(ctgry => {
            if (lastRole.role_categories.indexOf(ctgry.id) >= 0)
                newQsnByCtgry.push(ctgry)
        })
        setCrnCtgry(newQsnByCtgry)
    }
    const handleRolesInfo = async (id, info) => {
        setRolesChanged(false)
        const newInfo = { ...comInfo };
        newInfo[id] = info;
        setComInfo(newInfo);
        const lastRole = newInfo[id]?.slice(-1)[0]
        if (!questions.length) {
            const resInfo = await getRequest("questionByCategory")
            setQuestions(resInfo.data)
            roleBaseQsnHandler(resInfo.data, lastRole)
            // const newCurrentQsn = resInfo.data.find(qsn => qsn.id === lastRole.id)
            // setCurrentQsn(newCurrentQsn)
        }
        else {
            if (lastRole?.id) {
                roleBaseQsnHandler(questions, lastRole)
                // const newCurrentQsn = questions.find(qsn => qsn.id === lastRole.id)
                // setCurrentQsn(newCurrentQsn.questions)
            }
            else
                setCrnCtgry([])

        }

    }

    useEffect(() => {
        setRolesChanged(true)
    }, [currentCtgry])
    const handleQsnSelection = (id) => {
        const previousQsnId = [...qsnsId]
        const idx = previousQsnId.indexOf(id)
        if (idx >= 0) {
            const splicedRes = previousQsnId.filter(item => item !== id);
            setQsnsId(splicedRes)
        }
        else {
            setQsnsId([id, ...previousQsnId])
        }
    }
    const handleAssesSubmit = async () => {
        const body = {
            welcome_text: comInfo.welcome_text,
            industry: comInfo.all_industry,
            department: comInfo.all_department,
            location: comInfo.all_location,
            role: comInfo.all_role,
            questions_id: qsnsId
        }

        const isSucceed = await assessPostRequest("addWelcomeAssess", comInfo.company_id, body, "Assessment")
        if (isSucceed) {
            setReload(!reload);
            setState(false)
            setQsnsId([])
            setCrnCtgry([])
        }

    }
    useEffect(() => {
        setIndustries(industries?.map(info => (info.industry)))
        setAllDepartment(department?.map(info => (info.department)))
        setAllLocation(location?.map(info => (info.location)))
    }, [industries, department, roles, location])
    const handleAssessDelete = async (id) => {
        const isSucceed = await deleteRequest("deleteWelcomeAssesInfo", id, "Welcome")
        if (isSucceed) { setReload(!reload) };
    }
    const handleGetRequest = async (route) => {
        setLoading(true)
        const data = await getRequest(route)
        setAssessInfo(data?.data[0]?.assessment_info)
        setLoading(false)
    }
    useEffect(() => {
        if (comInfo?.company_id) {
            handleGetRequest(`getCompanyById/${comInfo?.company_id}`)
        }
    }, [comInfo?.company_id, reload])
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const checkCompanySelector = () => {
        if (comInfo.company_id && !assessInfo) {
            setState(!false)
        }
        else {
            swal("Failed!", `${!comList[0]?.assessment_info ? "Please select a Company and  try again." : "Please reset the information to add again"} `, "error", { dangerMode: true });
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={5}>
                <Typography variant="h4" gutterBottom>
                    Welcome
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Reset
                    assessInfo={assessInfo}
                    handleAssessDelete={handleAssessDelete}
                    loading={loading}
                    checkCompanySelector={checkCompanySelector}
                />


            </Grid>
            <Grid item xs={4}>
                <CompanyList
                    comInfo={comInfo}
                    setComInfo={setComInfo}
                    comList={comList}
                    reload={reload}
                    setComList={setComList}
                />
            </Grid>
            <Drawer
                anchor='right'
                open={state}
                onClose={() => setState(false)}
            >
                <Stack alignItems="center" justifyContent="center" mb={3}>
                    <Button
                        onClick={() => handleAssesSubmit(false)}
                        sx={{
                            position: 'absolute',
                            marginTop: '10px',
                            marginRight: '10px',
                            right: '0',
                            top: '0',
                        }} variant="outlined" >Save</Button>
                </Stack>

                <Box
                    sx={{
                        width: "60vw", p: 2, mt: 2, display: 'grid'
                    }}
                    role="presentation"
                >
                    <TextField
                        onBlur={(e) => handleAssessInfo("welcome_text", e.target.value)}
                        id="welcome_text"
                        label="Multiline"
                        multiline
                        rows={2}
                        placeholder="Enter Welcome Text"
                    />
                    <Box
                        sx={{
                            display: 'grid',
                            gap: 2,
                            mt: 1,
                            mb: 3,
                            gridTemplateColumns: 'repeat(2, 1fr)',
                        }}
                        role="presentation"
                    >
                        <CustomAutocomplete
                            handleAssessInfo={handleAssessInfo}
                            options={allIndustries}
                            label="Industry"
                            infoType="all_industry" />
                        <CustomAutocomplete
                            handleAssessInfo={handleAssessInfo}
                            options={allLocation}
                            label="location"
                            infoType="all_location" />
                        <CustomAutocomplete
                            handleAssessInfo={handleAssessInfo}
                            options={allDepartment}
                            label="department"
                            infoType="all_department" />
                        <Autocomplete
                            onChange={(event, newValue) => {
                                handleRolesInfo("all_role", newValue);
                            }}
                            multiple
                            options={roles}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.role}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        icon={icon}

                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.role}
                                </li>
                            )}
                            style={{ width: "28vw" }}
                            renderInput={(params) => (
                                <TextField {...params} label="role" placeholder="Favorites" />
                            )}
                        />
                    </Box>

                    {isRolesChanged &&
                        <QsnByCategory
                            currentCtgry={currentCtgry}
                            qsnsId={qsnsId}
                            handleQsnSelection={handleQsnSelection}
                        />
                    }

                </Box>
            </Drawer>
        </Grid>
    );
}
