import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        alignItems: "start",
    },
    others: {
        textAlign: "start",
        fontSize: "9px",
        width: "12%"
    },
    priority: {
        textAlign: "center",
        fontSize: "9px",
        width: "8%"
    },
    idx: {
        textAlign: "center",
        fontSize: "9px",
        width: "2%"
    },
    focus: {
        textAlign: "start",
        fontSize: "9px",
        width: "20%"
    },
    percentage: {
        textAlign: "center",
        fontSize: "9px",
        width: "7%"
    },
    heading: {
        width: "100%", textAlign: "center",
        fontSize: "16px",
        backgroundColor: "#D9EDF6"
    },
    stm: {
        width: "50%", textAlign: "start",
        fontSize: "9px"
    },
});

const DiagnosisRow = ({ items }) => {

    const rows = items.map((item, i) => (
        <View style={{ width: "100%" }} key={i + 1}>
            <Text style={styles.heading}>{item?.question}</Text>
            {item.data.map((qsn, index) => (
                <View key={index + 1} style={{
                    flexDirection: "row",
                    alignItems: "start",
                    marginBottom: "6px",
                    paddingTop: "2px",
                    paddingBottom: "2px",
                    backgroundColor: index % 2 === 0 ? "#D3D3D3" : "white"
                }} >
                    <Text style={styles.idx}>{index + 1}</Text>
                    <Text style={styles.stm}>{qsn.question}</Text>
                    <Text style={styles.priority}>{qsn.priority}</Text>
                    <Text style={styles.focus}>{qsn.priority_info}</Text>
                    {/* <Text style={styles.others}>{qsn.noOfSel}</Text> */}
                    <Text style={styles.others}>{qsn.options[qsn.selected - 1].option}</Text>
                    <Text style={styles.percentage}> {(qsn.options[qsn.selected - 1].selected / qsn.noOfSel * 100).toFixed(2)}%</Text>


                </View>
            ))}

        </View>
    ));
    return <Fragment>{rows}</Fragment>;
};

export default DiagnosisRow;