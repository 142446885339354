import toast from 'react-hot-toast';
import swal from 'sweetalert';
export const rootUrl = 'https://backend.businessinsight.app/';
// export const rootUrl = 'http://0.0.0.0:3333/';

// export const rootUrl = 'https://api.peopleaccelsolutions.com/';
const getTokens = () => {
  const parsedInfo = JSON.parse(localStorage.getItem('loginInfo'));
  return parsedInfo || {};
};
export const postRequest = async (route, body, message) => {
  try {
    const parsedInfo = await getTokens();
    if (parsedInfo.email === 'test@gmail.com') {
      swal('Sorry', 'You are not allowed to do any changes', 'error', { dangerMode: true });
      return false;
    }
    let isSuccess = false;
    const loading = toast.loading('Please wait...!');
    const response = await fetch(`${rootUrl + route}`, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${parsedInfo.token}`,
        'Content-Type': 'application/Json'
      },
      body: JSON.stringify(body)
    });
    const data = await response.json();
    toast.dismiss(loading);
    if (data.success) {
      swal(`${message} added`, `${message} has been  added successful.`, 'success');
      isSuccess = true;
    } else {
      toast.dismiss(loading);
      console.log(data, 'error form api ');
      swal(
        'Failed!',
        `${
          data?.error?.message ||
          data?.error?.sqlMessage ||
          'Something went wrong! Please try again.'
        }`,
        'error',
        { dangerMode: true }
      );
    }
    return isSuccess;
  } catch (error) {
    console.log(error);
    swal('Failed!', `"Something went wrong! Please try again."`, 'error', { dangerMode: true });

    return false;
  }
};
// https://librado.evamp.in
export const updateRequest = async (route, body, message) => {
  try {
    const parsedInfo = await getTokens();
    if (parsedInfo.email === 'test@gmail.com') {
      swal('Sorry', 'You are not allowed to do any changes', 'error', { dangerMode: true });
      return false;
    }
    let isSuccess = false;
    const loading = toast.loading('Please wait...!');
    const response = await fetch(`${rootUrl + route} `, {
      method: 'PUT',
      headers: {
        authorization: `Bearer ${parsedInfo.token}`,
        'Content-Type': 'application/Json'
      },

      body: JSON.stringify(body)
    });
    const data = await response.json();
    toast.dismiss(loading);
    if (data.success) {
      if (data?.access_token) {
        localStorage.setItem(
          'loginInfo',
          JSON.stringify({ token: data?.access_token, email: data.data.email })
        );
      }
      isSuccess = data.success;
      swal(`${message} updated`, `${message} has been  added successful.`, 'success');
    } else {
      swal(
        'Failed!',
        `${data?.error?.message || 'Something went wrong! Please try again.'}`,
        'error',
        { dangerMode: true }
      );
    }
    return isSuccess;
  } catch (error) {
    console.log(error);
    swal('Failed!', `"Something went wrong! Please try again."`, 'error', { dangerMode: true });
    return false;
  }
};
export const assessPostRequest = async (route, id, body, message) => {
  let isSuccess = false;
  const parsedInfo = await getTokens();
  if (parsedInfo.email === 'test@gmail.com') {
    swal('Sorry', 'You are not allowed to do any changes', 'error', { dangerMode: true });
    return false;
  }
  const loading = toast.loading('Please wait...!');

  await fetch(`${rootUrl + route}/${id}`, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${parsedInfo.token}`,
      'Content-Type': 'application/Json'
    },

    body: JSON.stringify(body)
  })
    .then((response) => response.json())
    .then((data) => {
      toast.dismiss(loading);
      if (data.success) {
        swal(`${message} added`, `${message} has been  added successful.`, 'success');
        isSuccess = true;
      }
    })
    .catch((error) => {
      toast.dismiss(loading);
      swal('Failed!', 'Something went wrong! Please try again.', 'error', { dangerMode: true });
    });
  return isSuccess;
};
export const deleteRequest = async (route, id, message) => {
  let isSuccess = false;
  const parsedInfo = await getTokens();
  if (parsedInfo.email === 'test@gmail.com') {
    swal('Sorry', 'You are not allowed to do any changes', 'error', { dangerMode: true });
    return false;
  }
  const willDelete = await swal({
    title: 'Are you sure?',
    text: 'Once deleted, you will not be able to recover!',
    icon: 'warning',
    buttons: true,
    dangerMode: true
  });
  if (willDelete) {
    const loading = toast.loading('Please wait...!');
    await fetch(`${rootUrl + route}/${id}`, {
      method: 'DELETE',
      headers: {
        // authorization: `Bearer NDM.vqZeyGMSOivpW1dn6OcloQ-AsYtDBwH4rF_D29t1lQOdXPkK2CNVFnFYecFd`,
        authorization: `Bearer ${parsedInfo.token}`,
        'Content-Type': 'application/Json'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        toast.dismiss(loading);
        if (data.success) {
          swal(`${message} Deleted`, `${message} has been Deleted successful.`, 'success');
          isSuccess = true;
        }
      })
      .catch((error) => {
        toast.dismiss(loading);
        swal('Failed!', 'Something went wrong! Please try again.', 'error', { dangerMode: true });
      });

    return isSuccess;
  }
};
export const GET_API = async (route) => {
  const parsedInfo = await getTokens();
  let isSuccess = [];
  const res = await fetch(`${rootUrl + route}`, {
    method: 'get',
    headers: {
      authorization: `Bearer ${parsedInfo.token}`
    }
  });
  const data = await res.json();
  isSuccess = await data?.data;
  return isSuccess;
};
export const getRequest = async (route) => {
  const parsedInfo = await getTokens();
  let isSuccess = [];
  const res = await fetch(`${rootUrl + route}`, {
    method: 'get',
    headers: {
      authorization: `Bearer ${parsedInfo.token}`
    }
  });
  const data = await res.json();
  isSuccess = await data;
  return isSuccess;
  // const token = await getTokens();
  // let isSuccess = [];
  // await fetch(`${rootUrl + route}`, {
  //     method: 'get',
  //     headers: {
  //         authorization: `Bearer ${token}`
  //     }
  // })
  //     .then(res => res.json())
  //     .then(data => {
  //         isSuccess = data

  //     })
  // return isSuccess;
};
export const getReportById = async (route) => {
  const parsedInfo = await getTokens();
  let isSuccess = [];
  await fetch(`${rootUrl + route}`, {
    method: 'get',
    headers: {
      authorization: `Bearer ${parsedInfo.token}`
    }
  })
    .then((res) => res.json())
    .then((data) => {
      isSuccess = data;
    });
  return isSuccess;
};
export const imgPostRequest = async (img) => {
  const parsedInfo = await getTokens();
  if (parsedInfo.email === 'test@gmail.com') {
    swal('Sorry', 'You are not allowed to do any changes', 'error', { dangerMode: true });
    return false;
  }
  let imgUrl = null;
  const formData = new FormData();
  formData.append('file', img);
  await fetch(`${rootUrl}upload`, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${parsedInfo.token}`
    },
    body: formData
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        let newUrl = data.data;
        imgUrl = `${rootUrl}img/${newUrl.split('/')[4]}`;
      }
    })
    .catch((error) => {
      toast.error('Img is not uploaded. try again');
    });
  return imgUrl;
};
