import React from 'react';
import { Document, Font, Page, Text, Image, StyleSheet, View } from '@react-pdf/renderer';
import header_img from './../assets/PeopleACCEL 11.png';
import Diagnosis_img from './../assets/peopleaccel 2.png';
import diag from './../assets/diag1.png';
import Diagnosis_foote from './../assets/WhatsApp Image 2022-07-21 at 5.51.49 PM.jpeg';

import smile1 from './../assets/smile1.png';
import smile2 from './../assets/smile2.png';
import smile3 from './../assets/smile3.png';
import smile4 from './../assets/smile4.png';
import smile5 from './../assets/smile5.png';
import comIMg from './../assets/building.png';
import Table from './table/Table';

const Pdf = ({ item, img, name, email, subReportTypes, pdfCnt }) => {
  const score = Math.round(item.happiness_score);
  // const score = item.happiness_score / item.no_of_report || item.happiness_score / item.selectionTime
  // console.log(item?.target_submission,"item?.target_submission")
  const styles = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 15
    },
    title: {
      fontSize: 18,
      margin: 5,
      fontFamily: 'Oswald'
    },
    author: {
      fontSize: 12,
      textAlign: 'center',
      marginBottom: 40
    },
    subtitle: {
      fontSize: 18,
      margin: 12,
      fontFamily: 'Oswald'
    },
    text: {
      margin: 12,
      fontSize: 14,
      textAlign: 'justify',
      fontFamily: 'Times-Roman'
    },
    img_box: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    score_box: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center'
    },

    image: {
      marginVertical: 15,
      marginHorizontal: 5
      // width: "100%"
    },
    topImg: {
      marginVertical: 15,
      marginHorizontal: 5,
      borderRadius: '50%',
      width: '100px',
      height: '100px'
    },
    header: {
      fontSize: 20,
      marginBottom: 20,
      margin: 12,
      textAlign: 'center',
      color: 'grey'
    },
    headerBox: {
      padding: 5,
      backgroundColor: '#FF914D',
      textAlign: 'center',
      marginVertical: 5
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey'
    },
    score: {
      fontSize: 16,
      padding: 8,
      borderRadius: '8px',
      backgroundColor: '#FF914D',
      textAlign: 'center'
    },
    halfWidth: {
      padding: 5,
      backgroundColor: '#FF914D',
      textAlign: 'center',
      width: '65%',
      marginVertical: '10px'
    }
  });

  Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
  });
  const culturalTableHeader = ['Sl', 'Statements', 'Most_likely', 'Least_likely', 'Selected'];
  const diagnosisTableHeader = ['Sl', 'Question', 'Priority ', 'Focus on', 'Diagnosis '];
  return (
    <Document>
      <Page style={styles.body} wrap>
        <Image style={styles.image} src={header_img} />

        <View style={styles.img_box}>
          <View>
            <Text style={styles.title}>
              Company Name: <span style={styles.header}> {name ? name : ' Name'}</span>
            </Text>
            <Text style={styles.title}>
              Email: <span style={styles.header}> {email ? email : 'Email'}</span>
            </Text>
            {subReportTypes && (
              <Text style={styles.title}>
                Report For : <span style={styles.header}> {subReportTypes}</span>
              </Text>
            )}
          </View>
          <Image style={styles.topImg} src={img ? img : comIMg} />
        </View>
        <View style={styles.headerBox}>
          <Text>{pdfCnt?.head1 || 'INSIGHT REPORT'}</Text>
        </View>
        <Text style={styles.text}>
          {pdfCnt?.para1 ||
            'INSIGHT REPORTAccelerating Performance with culture and organizational performance 5,'}
        </Text>
        <View style={styles.halfWidth}>
          <Text style={{ fontSize: 17 }}>{pdfCnt?.head2 || 'OVERALL HAPPINESS FACTOR'}</Text>
        </View>
        <Text style={styles.text}>
          {pdfCnt?.para2 ||
            'The overall hapiness score is based on average calculation across the entire set of questions. The scale ranges from 0-10 and each number is associated with an emoticon'}
        </Text>

        <View style={styles.score_box}>
          <Text style={styles.score}> Score: {score.toFixed(2)} </Text>

          <Image
            style={{ width: 50, height: 50 }}
            src={
              score < 2
                ? smile1
                : score >= 2 && score < 4
                ? smile2
                : score >= 4 && score < 6
                ? smile3
                : score >= 6 && score < 8
                ? smile4
                : score >= 8 && score < 10
                ? smile5
                : smile5
            }
          />
        </View>
        <View style={styles.halfWidth}>
          <Text style={{ fontSize: 17 }}>{pdfCnt?.head3 || 'TARGET CULTURAL STATEMENTS'}</Text>
        </View>
        <Text style={styles.text}>
          {pdfCnt?.para3 ||
            'Showcasing the gap in current and target operational cultural value statements for most likely and least likely among them '}
        </Text>

        <Table data={item?.target_submission} type={1} headers={culturalTableHeader} />
        <View style={styles.halfWidth}>
          <Text style={{ fontSize: 17 }}>{pdfCnt?.head4 || 'CURRENT CULTURAL STATEMENTS'}</Text>
        </View>
        <Text style={styles.text}>
          {pdfCnt?.para4 ||
            'Showcasing the gap in current and target operational cultural value statements for most likely and least likely among them '}
        </Text>

        <Table data={item?.current_submission} type={1} headers={culturalTableHeader} />
        <View style={styles.halfWidth}>
          <Text style={{ fontSize: 17 }}>{pdfCnt?.head5 || 'OPERATIONAL DIAGNOSIS'}</Text>
        </View>
        <Image style={styles.image} src={pdfCnt?.img_url || Diagnosis_img} />
        <Image style={styles.image} src={pdfCnt?.img2_url || diag} />

        <Table data={item?.answered_qsn} type={0} headers={diagnosisTableHeader} />

        <Image style={styles.image} src={pdfCnt?.img3_url || Diagnosis_foote} />

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  );
};

export default Pdf;
