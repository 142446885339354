import { Box, Button, Drawer, Stack, TextField } from '@mui/material';
import React from 'react';
import { postRequest, updateRequest } from 'src/utils/api';
import SingleSelector from '../SingleSelector';
import toast from 'react-hot-toast';
const UpdateUserDrawer = ({ comInfo, state, getUsers, setState, handleChange, usrInfo = {} }) => {
  const { email, account_type, id } = usrInfo;
  const handleUpdate = async () => {
    if (!comInfo.oldPwd) {
      toast.error('Please Enter Your Password...!');
      return;
    }
    const body = {
      // id: id,
      password: comInfo.oldPwd,
      newPassword: comInfo.newPwd,
      email: comInfo.newEmail
    };
    const res = await updateRequest(`updateUser/${id}`, body, 'User');
    if (res) {
      getUsers();
      setState(0);
    }
  };
  const boxStyle = {
    width: 450,
    p: 2,
    mt: 3,
    display: 'grid',
    gap: 2,
    marginTop: '10px',
    gridTemplateColumns: 'repeat(1, 1fr)'
  };

  return (
    <Drawer anchor="right" open={state} onClose={() => setState(false)}>
      <Box sx={boxStyle} role="presentation">
        <Stack alignItems="center">
          <TextField
            onBlur={(e, value) => handleChange(e, value)}
            id="newEmail"
            defaultValue={email}
            sx={{ width: '100%', marginBottom: '10px' }}
            label="New Email"
            placeholder="New Email"
          />
          <TextField
            onBlur={(e, value) => handleChange(e, value)}
            id="oldPwd"
            sx={{ width: '100%', marginBottom: '10px' }}
            label="Old password"
            placeholder="Old password"
          />
          <TextField
            onChange={(e, value) => handleChange(e, value)}
            id="newPwd"
            error={false}
            sx={{ width: '100%', marginBottom: '10px' }}
            label="New password"
            placeholder="New password"
          />
          <TextField
            onChange={(e, value) => handleChange(e, value)}
            id="cnfPwd"
            error={comInfo.cnfPwd === comInfo.newPwd ? false : true}
            sx={{ width: '100%', marginBottom: '10px' }}
            label="Confirm password"
            placeholder="Confirm password"
          />
          {/* <SingleSelector
            handleAssessInfo={handleAssessInfo}
            options={['Admin', 'User']}
            label="User Type"
            id="account_type"
          /> */}
        </Stack>
        <Stack alignItems="center" justifyContent="center">
          <Button
            disabled={comInfo.newPwd && comInfo.cnfPwd === comInfo.newPwd ? false : true}
            onClick={() => handleUpdate()}
            variant="outlined"
            color="success"
          >
            Update
          </Button>
        </Stack>
      </Box>
    </Drawer>
  );
};

export default UpdateUserDrawer;
