import { Box, Card, Checkbox, FormControlLabel, Paper } from '@mui/material';
import React, { useState } from 'react';

const QsnByCategory = ({ currentCtgry, qsnsId, handleQsnSelection }) => {
    const [shown, setIsShown] = useState(false)
    return (
        <>
            {currentCtgry.map(catItem =>
                <>

                    <h1 sx={{ textTransform: 'uppercase' }}>{catItem.category_name}</h1>
                    <Box sx={{
                        display: 'grid',
                        gap: 1,
                        mt: 1,
                        gridTemplateColumns: 'repeat(2, 1fr)',
                    }}>
                        {catItem.questions?.map(item =>
                            <Card
                                onMouseEnter={() => setIsShown(item.id)}
                                onMouseLeave={() => setIsShown(0)} sx={{ p: 1, boxShadow: 3 }} key={item.id} >
                                <FormControlLabel
                                    control={<Checkbox checked={qsnsId.indexOf(item.id) >= 0 ? true : false}
                                        onChange={() => handleQsnSelection(item.id)} />}
                                    label={shown === item.id ? item.question : `${item.question.slice(0, 42)}...`} />
                            </Card>

                        )}

                    </Box>
                </>
            )
            }
        </>
    );
};

export default QsnByCategory;