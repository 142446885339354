import { useState, useEffect } from 'react';
import { TextField, Autocomplete } from '@mui/material';
import { getRequest } from 'src/utils/api';

export default function CompanyList({ setComInfo, reload, comInfo, comList, setComList }) {
    const handleChange = (e, value) => {
        const newInfo = { ...comInfo };
        newInfo[e.target.id.split('-')[0]] = value;
        setComInfo(newInfo);
    }
    const handleGetRequest = async () => {
        const data = await getRequest("getComWithoutReport")
        setComList(data)
    }
    useEffect(() => {
        handleGetRequest();
 
    }, [reload])

    return (
        <Autocomplete
            disablePortal
            id="company_id"
            name="first"
            fullWidth
            onChange={(event, newValue) => handleChange(event, newValue.id)}
            getOptionLabel={option => option.company_name}
            options={comList}
            renderInput={(params) =>
                <TextField  {...params}
                    label="Company Name" />}
        />
    );
}  
