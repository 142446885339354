import swal from 'sweetalert';
import { useState, useEffect } from 'react';
import { Grid, Button, Stack, Typography, Card, Box, TextField, Drawer, Autocomplete, Checkbox } from '@mui/material';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
// components
import Page from '../components/Page';
import CompanyList from '../components/CompanyList';
import HappyCard from '../components/HappyCard';
import { assessPostRequest, deleteRequest, getRequest, postRequest, updateRequest } from 'src/utils/api';
import CustomCheckBox from 'src/components/CustomCheckBox';
import MultipleInput from 'src/components/MultipleInput';
import Reset from 'src/components/Reset';
import CustomSkeleton from 'src/components/CustomSkeleton';

export default function Statements() {
  const [isLoading, setIsLoading] = useState(true)
  const [comList, setComList] = useState([]);
  const [stmAssessInfo, setStmAssessInfo] = useState(null);
  const [reload, setReload] = useState(false);
  const [statementQsns, setStatementQsn] = useState([]);
  const [comInfo, setComInfo] = useState([])
  const [editQsn, setEditingQsn] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(true);
  const [currentSteps, setCurrentSteps] = useState([]);
  const [targetSteps, setTargetSteps] = useState([]);
  const [state, setState] = useState(false);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const handleChange = (e) => {
    const newInfo = { ...comInfo };
    newInfo[e.target.id.split('-')[0]] = e.target.value;
    setComInfo(newInfo);
  }
  const handleStatementInfo = (statements) => {
    // const newStatements = statements.map(statement => statement.id)
    // console.log(newStatements, "newStatements")
    const newInfo = { ...comInfo };
    newInfo["statements_id"] = statements;
    setComInfo(newInfo);
  }
  const handleAddStatement = async (id) => {
    const body = {
      qsn: comInfo?.question,
      default: checked,
    }
    const isSucceed = await postRequest("addStatementQsn", body, "Statement question")
    if (isSucceed) {
      setReload(!reload);
      document.getElementById("question").value = ''

    }

  }
  const handleUpdateStatement = async (id) => {
    const body = {
      qsn: comInfo?.question,
      default: checked,
    }
    const isSucceed = await updateRequest(`updateStatementQsn/${id}`, body, "Statement question")
    if (isSucceed) {
      setReload(!reload)
      document.getElementById("question").value = ''
    }
  }
  const handleAssessmentSubmit = async (id) => {

    let body = {
      most_likely: comInfo.most_likely,
      least_likely: comInfo.least_likely,
      current_stm_header: comInfo.current_stm_header,
      current_steps: Object.values(currentSteps),
      target_stm_header: comInfo.target_stm_header,
      target_steps: Object.values(targetSteps),
      statements_id: comInfo.statements_id.map(statement => statement.id)
    }

    let isSucceed = await assessPostRequest("addStatementAssessInfo", comInfo.company_id, body, "selectable Qsn")
    if (isSucceed) {
      setReload(!reload)
      setOpenDrawer(false)
      setCurrentSteps([])
      setTargetSteps([])
    }
  }
  const handleAssessmentUpdate = async (id) => {
    let body = {
      most_likely: comInfo.most_likely,
      least_likely: comInfo.least_likely,
      current_stm_header: comInfo.current_stm_header,
      current_steps: currentSteps,
      target_stm_header: comInfo.target_stm_header,
      target_steps: targetSteps

    }

    const isSucceed = await updateRequest(`updateStatementAssessInfo/${stmAssessInfo?.id}`, body, "Statement ")
    if (isSucceed) {
      setReload(!reload)
      setOpenDrawer(false)
    }
  }
  const handleDelete = async (id) => {
    const isSucceed = await deleteRequest("deleteStatementQsn", id, "statement Qsn")
    if (isSucceed) { setReload(!reload) }

  }
  const handleAssessmentDelete = async () => {
    const isSucceed = await deleteRequest("deleteStatementAssessInfo", stmAssessInfo?.id, "Assessment Info")
    if (isSucceed) { setReload(!reload) }

  }
  const checkCompanySelector = () => {

    if (comInfo.company_id && !stmAssessInfo) {
      setOpenDrawer(!false)
    }
    else if (stmAssessInfo) {
      swal("Failed!", "Reset the company  info to add again", "error", { dangerMode: true });
    }
    else {
      swal("Failed!", "Please select a Company and  try again.", "error", { dangerMode: true });
    }
  }

  const handleGetRequestById = async (route) => {
    setLoading(true)
    const data = await getRequest(route)
    setStmAssessInfo(data?.data[0]?.selectable_statement)
    setLoading(false)
  }
  useEffect(() => {
    if (comInfo?.company_id) {
      handleGetRequestById(`getCompanyById/${comInfo?.company_id}`)
    }
  }, [comInfo?.company_id, reload])
  const handleGetRequest = async () => {
    const data = await getRequest("getStatementQsn")
    setStatementQsn(data.data)
    setIsLoading(false)
  }
  // const handleGetRequest = async () => {
  //   const data = await getRequest("getAllWelcomeInfo")
  //   setComList(data)
  // }
  useEffect(() => {
    handleGetRequest()
  }, [reload])
  const handleEdit = (qsn) => {
    setEditingQsn(qsn)
    setState(!state)
  }
  const handleAddQsn = () => {
    setEditingQsn(null)
    setState(!state)
  }
  const boxStyle = {
    width: 450, p: 2, mt: 3, display: 'grid',
    gap: 2,
    marginTop: '10px',
    gridTemplateColumns: 'repeat(1, 1fr)',
  }
  const boxContainer = {
    width: "60vw", p: 2, mt: 2, display: 'grid'

  }

  // <Box
  //                   sx={{
  //                       width: "60vw", p: 2, mt: 2, display: 'grid'
  //                   }}
  //                   role="presentation"
  //               >
  return (
    <Page title="Dashboard: Blog">
      <Card sx={{ p: 3 }}>

        <Grid container >
          <Grid item xs={4}>
            <Typography variant="h4" gutterBottom>
              Cultural & Target Statement
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Reset
              assessInfo={stmAssessInfo}
              handleAssessDelete={handleAssessmentDelete}
              loading={loading}
              checkCompanySelector={checkCompanySelector}
            />

          </Grid>
          <Grid item ml={2} xs={3}>
            <CompanyList
              comInfo={comInfo}
              setComInfo={setComInfo}
              comList={comList}
              setComList={setComList} />
          </Grid>
          <Stack direction="row" justifyContent="end" pl={2}>
            <Button
              onClick={handleAddQsn}
              variant="outlined"
            >Add Statements
            </Button>
          </Stack>
        </Grid>
        {isLoading ? <CustomSkeleton hid={110} /> :
          <Drawer
            anchor='right'
            open={state}
            onClose={() => setState(false)}
          >
            <Box
              sx={boxStyle}
              role="presentation"
            >
              <Stack alignItems="center" direction="row" justifyContent="space-between" >
                <TextField
                  onBlur={(e, value) => handleChange(e, value)}
                  id="question"
                  sx={{ width: '100%' }}
                  label={editQsn ? editQsn.qsn : "Statement"}
                  placeholder="Cultural & Target Statement "
                />
                <CustomCheckBox setChecked={setChecked} checked={checked} />
              </Stack>
              <Stack alignItems="center" justifyContent="center">
                <Button
                  onClick={() => editQsn ? handleUpdateStatement(editQsn.id) : handleAddStatement()}
                  variant="outlined"
                  color="success"
                >{editQsn ? "Update" : "Save"}
                </Button>
              </Stack>
            </Box>

          </Drawer>}
        <Grid container spacing={1}>
          {statementQsns?.map(qsn =>
            <HappyCard
              key={qsn.id}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
              qsn={qsn}
              multiCol={1}
            />)}
        </Grid>
        <Drawer
          anchor='right'
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
        >
          <Box sx={{ width: 450 }} mb={3} p={2} >
            <Autocomplete
              onChange={(event, newValue) => {
                handleStatementInfo(newValue)
              }}
              multiple
              options={statementQsns}
              disableCloseOnSelect
              getOptionLabel={(statement) => statement.qsn}
              renderOption={(props, statement, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    checked={selected}
                  />
                  {statement.qsn}
                </li>
              )}
              style={{ width: "30vw" }}
              renderInput={(params) => (
                <TextField {...params} label="Statements" placeholder="Favorites" />
              )}
            />
            <Stack m={2} alignItems="center" direction="row" justifyContent="space-between" >
              <TextField
                onBlur={(e, value) => handleChange(e, value)}
                id="most_likely"
                sx={{ width: '45%' }}
                label={stmAssessInfo?.most_likely || "most likely"}
                type="number"
                placeholder="most likely"
              />
              <TextField
                onBlur={(e, value) => handleChange(e, value)}
                id="least_likely"
                type="number"
                sx={{ width: '45%' }}
                label={stmAssessInfo?.most_likely || "least likely"}
                placeholder="least likely"
              />
            </Stack>

            <TextField
              onBlur={(e, value) => handleChange(e, value)}
              id="current_stm_header"
              sx={{ width: '100%', marginBottom: 1 }}
              label={stmAssessInfo?.current_stm_header || " Current Header text"}
              placeholder="Current Header text"
            />
            <TextField
              onBlur={(e, value) => handleChange(e, value)}
              id="target_stm_header"
              sx={{ width: '100%', marginBottom: 1 }}
              label={stmAssessInfo?.target_stm_header || " Target Header text"}
              placeholder="Target Header text"
            />


            <MultipleInput
              label={"Current steps"}
              setOptionInfo={setCurrentSteps}
              optionInfo={currentSteps} />
            <MultipleInput
              label={"Target steps"}
              setOptionInfo={setTargetSteps}
              optionInfo={targetSteps} />

            <Stack direction="row" alignItems="center" justifyContent="center">
              <Button
                color="success"
                onClick={stmAssessInfo ? handleAssessmentUpdate : handleAssessmentSubmit}
                sx={{ width: '30%', marginTop: 3 }}
                variant="outlined" >
                Save
              </Button>
            </Stack>
          </Box>
        </Drawer>
      </Card >
    </Page >
  );
}
